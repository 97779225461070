import React from 'react';
import * as styles from './ProjectCallout.module.scss';

import LinkButton from '../Buttons/LinkButton/LinkButton';
import IconArrowRight from '../Icons/IconArrowRight';

const ProjectCallout = (): JSX.Element => (
    <section>
        <div className={`contain containMedium ${styles.ProjectCallout}`}>
            <div className={styles.ProjectCalloutBody}>
                <h3 className="text-heading gold">Ready to start a new project?</h3>
            </div>
            <LinkButton href="/project-planner" label="Start your project" icon={<IconArrowRight size="24px" stroke="slate-900" />} />
        </div>
    </section>
);

export default ProjectCallout;
