import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText, ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

interface RichTextBlockProps {
    content: RenderRichTextData<ContentfulRichTextGatsbyReference>,
    spacing?: 'space-y-1'|'space-y-2'|'space-y-3',
    className?: string
}

const options = {
    renderNode: {
        [BLOCKS.HEADING_3]: ({ nodeType }: { nodeType: string }, children: React.ReactNode) => (<h3 key={nodeType} className="text-heading">{children}</h3>),
        [BLOCKS.PARAGRAPH]: ({ nodeType }: { nodeType: string }, children: React.ReactNode) => (<p key={nodeType} className="text-paragraph">{children}</p>)
    }
};

const RichTextBlock = ({ content, className, spacing = 'space-y-3' }: RichTextBlockProps): JSX.Element | null => {
    if (!content) {
        return null;
    }

    return (
        <div className={`${spacing} ${className}`}>
            {renderRichText(content, options)}
        </div>
    );
};


export default RichTextBlock;
